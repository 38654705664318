import React, { useRef } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import ArrowsWhitePath from "../images/arrows-white.svg";
import ArrowsBlackPath from "../images/arrows-black.svg";
import BackgroundImagePath from "../images/esports/nxrt-esports4.png";
import DarkBackgroundImagePath from "../images/nxrt-background-blue.svg";
import LightBackgroundImagePath from "../images/nxrt-background.svg";
import BackgroundImage1Path from "../images/esports/nxrt-esports3.jpg";
import BackgroundImage2Path from "../images/esports/nxrt-esports2.jpg";
import BackgroundImage3Path from "../images/esports/nxrt-esports4-2.jpg";
import SimxImage1Path from "../images/esports/nxrt-esports.jpg";
import SimxImage2Path from "../images/esports/nxrt-esports-6.png";
import SimxImage3Path from "../images/esports/nxrt-esports4.jpg";
import SimxImage4Path from "../images/esports/nxrt-esports-7.png";
import SiteNavigation from "../components/siteNavigation";
import { Routes } from "../components/layout/header";

//#region Styled Components

const StyledBackgroundContainer = styled.div`
  background: url('${DarkBackgroundImagePath}');
  background-size: cover;
`;

//#region Hero
const StyledHeroSection = styled.section`
  min-height: 900px;
  padding: 2rem 5rem 2rem 5rem;
  background: url('${BackgroundImagePath}');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledHeroHeaderContainer = styled.div`
  max-width: 1344px;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroHeader = styled.h1`
  text-transform: uppercase;
  line-height: 1.2;

  > span {
    display: inline-block;
  }

  > span:nth-of-type(1) {
    margin-top: 15rem;
    margin-left: 4rem;
    font-weight: 300;
    font-size: 8rem;
    text-transform: uppercase;
    letter-spacing: 14px;
  }

  > span:nth-of-type(2) {
    margin-left: 25rem;
    font-weight: 300;
    font-size: 6rem;
    letter-spacing: 9px;
  }

  @media (max-width: 78rem) {
    > span:nth-of-type(1) {
      margin-top: 15rem;
      margin-left: 4rem;
      font-size: 4rem;
    }

    > span:nth-of-type(2) {
      margin-left: 30rem;
      font-size: 3rem;
    }
  }

  @media (max-width: 53rem) {
    > span:nth-of-type(1) {
      margin-top: 10rem;
    }

    > span:nth-of-type(2) {
      margin-left: 0;
      display: inline-block;
      width: 100%;
      text-align: right;
    }
  }

  @media (max-width: 44rem) {
    > span:nth-of-type(1) {
      margin-left: 2rem;
      font-size: 3rem;
    }

    > span:nth-of-type(2) {
      font-size: 2rem;
    }
  }
`;

const StyledHeroDescriptionContainer = styled.div`
  max-width: 1354px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroDescription = styled.div`
  margin-left: -5rem;
  max-width: 17rem;
  padding: 20px;
  align-self: flex-start;
  border: 1px solid white;
  font-size: 1.3rem;
  line-height: 1.5;
`;

const StyledImageBackgrounds = styled.div`
  max-width: 1354px;
  width: calc(100% - 10rem);
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  > img {
    margin: 1.25%;
    align-self: flex-start;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    width: 70%;
    margin-left: -5rem;
    margin-top: 50px;
    opacity: 0.4;
  }

  > img:nth-child(2) {
    width: 20%;
    align-self: flex-end;
    opacity: 0.85;
  }

  > img:nth-child(3) {
    width: 30%;
    margin-left: 60%;
    opacity: 0.85;
  }
`;

const StyledHeroArrowsContainer = styled.div`
  margin-top: 2rem;
  max-width: 1354px;
  width: 100%;
  z-index: 1;
  height: 1px;
  margin-bottom: 10rem;
`;

const StyledHeroArrows = styled.img`
  margin-left: 5rem;
  height: 10rem;
`;
//#endregion

//#region Simx Section

const StyledSimxSection = styled.section`
  padding: 3rem;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    margin-bottom: 3rem;
    font-size: 5.5rem;
    font-weight: 300;
    letter-spacing: 9px;
    text-transform: uppercase;
    text-align: center;
  }
`;

const StyledSimxText = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  max-width: 45rem;
`;

const StyledSimxImages = styled.div`
  margin-top: 10rem;
  width: 60%;
  max-width: 68rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 10rem auto auto;
  opacity: 0.6;

  @media (max-width: 75rem) {
    width: 100%;
  }

  img {
    width: 100%;
    align-self: stretch;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    height: 10rem;
    width: 70%;
    margin-left: 30%;
  }

  > img:nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  > img:nth-child(3) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    margin-top: 10%;
  }

  > img:nth-child(4) {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
    margin: 0 10%;
    width: 80%;
  }
`;

//#region Components Section
const StyledComponentsSection = styled.section`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;

  h2 {
    margin-top: 0;
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
  }

  @media (max-width: 50rem) {
    padding: 5rem;
  }
`;

const StyledContentContainer = styled.div`
  margin-top: 15rem;
  width: 100%;
  max-width: 45rem;

  > p {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }
`;

const StyledContentHeadline = styled.div`
  margin-bottom: 3rem;
  position: relative;

  > h3 {
    margin: 0;
    font-weight: normal;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }

  > span {
    position: absolute;
    font-size: 15rem;
    line-height: 1;
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
    color: gray;
  }
`;

const StyledComponentsArrows = styled.img`
  margin-top: 6.5rem;
  height: 10rem;
`;
//#endregion

//#region Navigation
const StyledNavigationContent = styled.div`
  flex-grow: 1;
  background: url('${LightBackgroundImagePath}');
  background-size: cover;
  background-position-x: -900px;
`;
//#endregion

//#endregion

const IndexPage: React.FC = () => {
  const realtimeImmersionRef = useRef();
  const railsuitRef = useRef();

  return (
    <Layout currentRoute={Routes.IndustryESports}>
      <StyledBackgroundContainer>
        <StyledHeroSection>
          <StyledHeroHeaderContainer>
            <StyledHeroHeader>
              <span>Racing</span>
              <br />
              <span>Simulation</span>
            </StyledHeroHeader>
          </StyledHeroHeaderContainer>
          <StyledHeroDescriptionContainer>
            <StyledHeroDescription>
              <p>
                <strong>Simracing</strong> has never been more attractive!
              </p>
            </StyledHeroDescription>
          </StyledHeroDescriptionContainer>
          <StyledHeroArrowsContainer>
            <StyledHeroArrows
              src={ArrowsWhitePath}
              onClick={() =>
                document
                  .getElementById("content")
                  .scrollIntoView({ behavior: "smooth" })
              }
            />
          </StyledHeroArrowsContainer>
          <StyledImageBackgrounds>
            <img src={BackgroundImage1Path} />
            <img src={BackgroundImage2Path} />
            <img src={BackgroundImage3Path} />
          </StyledImageBackgrounds>
        </StyledHeroSection>
      </StyledBackgroundContainer>
      <StyledNavigationContent id="content">
        <StyledSimxSection ref={railsuitRef}>
          <h2>SIMX</h2>
          <StyledSimxText>
            NXRT's SimX is the world's first real vehice racing simulation
            system. The technology allows people to conduct virtual races within
            real cars enabling the most realistic racing simulation available,
            and companies to activate potential clients in a non precedented
            way.
          </StyledSimxText>
          <StyledSimxImages>
            <img src={SimxImage1Path} />
            <img src={SimxImage2Path} />
            <img src={SimxImage3Path} />
            <img src={SimxImage4Path} />
          </StyledSimxImages>
        </StyledSimxSection>
        <StyledComponentsSection ref={realtimeImmersionRef}>
          <h2>Components</h2>
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>1</span>
              <h3>X3_Sensorkit:</h3>
            </StyledContentHeadline>
            <p>
              The X3_Sensorkit can transfer any car into a racing simulator
              within minutes, enabling pilots to use the common inputs (e.g.:
              steering wheel, gas pedal &amp; break) while competing in a
              virtual competition.
            </p>
          </StyledContentContainer>
          <StyledComponentsArrows src={ArrowsBlackPath} />
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>2</span>
              <h3>Mishbild (Mixed Reality):</h3>
            </StyledContentHeadline>
            <p>
              Mishbild is designed to blur the lines between the real world and
              the virtual world for a more immersive experience. Being a unique
              algorithm integrated into a VR headset replacing the real
              windshield and windows with a virtual environment, enabling the
              driver to see the vehicles real interior &amp; hands while racing
              in a virtual setting.
            </p>
          </StyledContentContainer>
          <StyledComponentsArrows src={ArrowsBlackPath} />
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>3</span>
              <h3>Racing Environment:</h3>
            </StyledContentHeadline>
            <p>
              As our level design team is highly passionate about racing games,
              we offer unique self-designed racing environments made with a VR
              first approach. We also partner up with game publishers ensuring
              accessibility to the most famous racing games.
            </p>
          </StyledContentContainer>
        </StyledComponentsSection>
      </StyledNavigationContent>
    </Layout>
  );
};

export default IndexPage;
